import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'
import { HOME_TABS } from '../../_app/constants/homeTabs'

export const AggregatedPreProcessQueries: QueryResolvers = {
  async aggregatedPreProcess(parent, args, ctx) {
    const fallbackRecommendTabInfoResponse = {
      recommend_tab: 'none',
      tab_orders: [
        HOME_TABS.AGGREGATED.LOWERCASE_ID,
        HOME_TABS.FLEA_MARKET.LOWERCASE_ID,
        HOME_TABS.COMMUNITY.LOWERCASE_ID,
        HOME_TABS.BUSINESS.LOWERCASE_ID,
        HOME_TABS.BIZ_ACCOUNT.LOWERCASE_ID,
        HOME_TABS.USERS.LOWERCASE_ID,
      ],
    }

    const fallbackQueryPreProcessInfoResponse = {
      query: args.data.query,
      reformulation_query: '',
      type: '',
    }

    const resp = await ctx.services.aggregatedPreProcess.aggregatedPreProcess({
      query: args.data.query,
      regionId: args.data.regionId,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    //기본값 처리
    if (!resp) {
      return {
        recommend_tab_info: fallbackRecommendTabInfoResponse,
        query_pre_process_info: fallbackQueryPreProcessInfoResponse,
      }
    } else if (
      !resp.recommend_tab_info ||
      !resp.recommend_tab_info.tab_orders ||
      !resp.recommend_tab_info.recommend_tab
    ) {
      if (!resp.query_pre_process_info || !resp.query_pre_process_info.query) {
        return {
          recommend_tab_info: fallbackRecommendTabInfoResponse,
          query_pre_process_info: fallbackQueryPreProcessInfoResponse,
        }
      } else {
        return {
          recommend_tab_info: fallbackRecommendTabInfoResponse,
          query_pre_process_info: resp.query_pre_process_info,
        }
      }
    } else {
      if (!resp.query_pre_process_info || !resp.query_pre_process_info.query) {
        return {
          recommend_tab_info: resp.recommend_tab_info,
          query_pre_process_info: fallbackQueryPreProcessInfoResponse,
        }
      }
    }

    const tabIds = Object.values(HOME_TABS)
      .filter((tab) => tab.LOWERCASE_ID !== HOME_TABS.UNKNOWN.LOWERCASE_ID)
      .map((tab) => tab.LOWERCASE_ID)

    const tabOrders = resp.recommend_tab_info.tab_orders.filter((tab) =>
      (tabIds as string[]).includes(tab)
    )

    return {
      recommend_tab_info: {
        recommend_tab: resp.recommend_tab_info.recommend_tab,
        tab_orders: tabOrders,
      },
      query_pre_process_info: resp.query_pre_process_info,
      experiment: resp.experiment,
    }
  },
}
