import type {
  AggregatedRecommendTabNone,
  AggregatedTabs,
  Resolvers,
  ReformulationQueryType,
} from '../../__codegen__/__graphql__/resolvers'

export const AggregatedPreProcess: Resolvers['AggregatedPreProcess'] = {
  recommendTabInfo(parent) {
    return {
      recommendTab: toUpperCaseTab(
        parent.recommend_tab_info.recommend_tab,
        'NONE'
      ) as AggregatedRecommendTabNone,
      tabOrders: parent.recommend_tab_info.tab_orders.map(
        (tab) => toUpperCaseTab(tab, 'ALL') as AggregatedTabs
      ),
    }
  },
  queryPreProcessInfo(parent) {
    return {
      query: parent.query_pre_process_info.query ?? '',
      reformulationQuery: parent.query_pre_process_info.reformulation_query,
      type: toUpperCaseReformulationQueryType(
        parent.query_pre_process_info.type,
        'NONE'
      ) as ReformulationQueryType,
    }
  },
}

const toUpperCaseReformulationQueryType = (
  type: string,
  defaultValue: string
) => {
  switch (type) {
    case 'type_correction':
      return 'TYPO_CORRECTION'
    case 'none':
      return 'NONE'
    default:
      return defaultValue
  }
}

const toUpperCaseTab = (tab: string, defaultValue: string) => {
  switch (tab) {
    case 'all':
      return 'ALL'
    case 'flea_market':
      return 'FLEA_MARKET'
    case 'community':
      return 'COMMUNITY'
    case 'business':
      return 'BUSINESS'
    case 'biz_account':
      return 'BIZ_ACCOUNT'
    case 'users':
      return 'USERS'
    default:
      return defaultValue
  }
}
