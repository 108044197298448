import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const HotKeywordsQueries: QueryResolvers = {
  async hotKeywords(parent, args, ctx) {
    return await ctx.services.searchV2.getHotKeywords({
      regionId: args.data.regionId,
      referrer: {
        funnelFromId: args.referrer.funnelFromId,
        experiment: ctx.experiment,
      },
    })
  },
}
